.span-status {
  width: 60px;
  height: 36px;
  padding: 0 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  strong {
    font-size: 20px;
    line-height: 20px;
    font-weight: $fontHardBold;
    margin-bottom: 4px;
  }

  span {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.red {
    border-left: 3px solid $red-status;
  }
  
  &.orange {
    border-left: 3px solid $orange-status;
  }
  
  &.green {
    border-left: 3px solid $green-status;
  }
  
  &.yellow {
    border-left: 3px solid $yellow-status;
  }
  
  &.purple {
    border-left: 3px solid $purple-status;
  }
  
  &.blue {
    border-left: 3px solid $blue-status;
  }
}

button {
  height: 30px;
}

.ant-tooltip-inner {
  padding: 15px;

  ul {
    list-style: none;
    width: 200px;

    > li {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      color: $white;
      font-size: 12px;
      font-weight: $fontBold;

      span {
        position: relative;
        padding-left: 15px;

        &::before {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          left: 0;
          top: 8px;
          background: $white;
        }
      }

      &.reports {
        color: $green;

        span::before {
          background: $green;
        }
      }
      
      &.others {
        color: $blue;

        span::before {
          background: $blue;
        }
      }
    }
  }
}

.ant-layout-content {
  background-color: #000000;
  overflow: hidden;
  padding: 0 0 0 66px;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;   

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  height: 100%;

  > div {
    height: 100%;
  }
  
  .hover-btn-row-table {
    background: transparent;
    border: none;

    &:hover {
      color: $neutral-400;
    }
  }

  .ant-row {
    height: 100%;

    .ant-col {
      height: 100%;
      padding: 0;
      padding-left: $pagePadding;
      overflow-y: auto;
      padding-left: 0;

      &:first-child {
        padding-left: 0;
      }

      .panel-group {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: auto;

        .panel {
          margin-bottom: 2px;
          &:before {
            border: none;
          }
        }

        .ant-checkbox-wrapper {
          font-size: 16px;
          width: 100%;
          min-height: 36px;
          line-height: 36px;
          
          &.ant-checkbox-wrapper-checked {
            color: $green;
          }
        }
      }
    }
  }
}

.sub-title-purple {
  color: $purple100;
  margin-left: 5px;
}

.menu-container {
  position: absolute;
  background-color: transparent;
  z-index: 102;

  span {
    padding: 4px 10px;

    &.followUp {
      background: url('../img/followUp.svg') no-repeat 0 0;
    }
  
    &.peerReview {
      background: url('../img/peerReview.svg') no-repeat 0 0;
    }
  
    &.teachingFiles {
      background: url('../img/teachingFiles.svg') no-repeat 0 0;
    }
  
    &.peerLearning {
      background: url('../img/peerLearning.svg') no-repeat 0 0;
    }
  
    &.incidentalFindings {
      background: url('../img/incidentalFindings.svg') no-repeat 0 0;
    }
  
    &.imageQa {
      background: url('../img/imageQa.svg') no-repeat 0 0;
    }

    & + p {
      margin-top: 8px;
    }
  }
}

.sidebar-show-user-info {
  background: $black !important;

  .personal-info,
  .contact-info,
  .other-info {
    strong {
      display: inline-block;
      margin-bottom: 16px;
    }

    label {
      color: $mediumGray;
    }

    input {
      color: $mediumGray;
      
      &:hover,
      &:focus,
      &:active {
        border-color: $purple100;
      }
    }

    .ant-picker-focused {
      border-color: $purple100;
    }

    .ant-select-selection-item {
      color: $mediumGray;
    }
    .ant-select {
        &.ant-select-focused,
        &:hover,
        &:focus,
        &:active {
          .ant-select-selector {
            border-color: $purple100 !important;
            color: $white;
          }
        }
    }

    .ant-picker {
      width: 100%;

      &:hover,
      &:focus,
      &:active {
        border-color: $purple100;
      }
    }
  }

  .button-wrapper {    
    .cancel {
      background-color: transparent;
      color: $purple100;
      border-color: $darkGray;

      &:hover,
      &:focus,
      &:active {
        border-color: $purple100;
        color: $purple100;
      }
    }

    .submit {
      background-color: $purple100;
      color: $black;
      border-color: $purple100;

      &:hover,
      &:focus,
      &:active {
        border-color: $purple100;
        color: $black;
        background-color: $mutedPurple;
      }
    }
  }
}

.ant-picker-panel-layout {

  .ant-picker-date-panel {
    .ant-picker-cell-selected > .ant-picker-cell-inner {
      background: $purple100;
    }
  }

  .ant-picker-footer {
    .ant-picker-today-btn {
      color: $purple100;

      &:hover {
        color: $mutedPurple;
      }
    }
  }
}

.ant-select-item-option {
  &[aria-selected="true"] {
    background: $mutedPurple !important;

    path {
      fill: $white;
    }
  }
}

.ant-checkbox-wrapper {
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $purple100;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: $purple100;
      border-color: $purple100;

      &::after {
        transform: rotate(45deg) scale(1.3) translate(-50%,-50%);
      }
    }
  }

  .ant-checkbox-inner {
    background: $black;
    width: 20px;
    height: 20px;
  }
}

.worklist-controller {
  &:not(.bottom) {
    margin-bottom: 0 !important;
    min-height: initial;
    z-index: 10;

    &.right {
      border-left: 2px solid #000;
      min-width: 360px;
      width: 360px;
      max-width: 360px;
      height: 100%;
    }

    &.free {
      border: 1px solid $darkGray;
      position: absolute;
      z-index: 101;
      width: 500px;
      height: 771px;
      box-shadow: 0 10px 50px 0 #00000080;

      .panel-header {
        > div:not(.controller-action) {
          cursor: move;
        }
      }
    }

    > div {
      padding: 12px 0;

      .panel-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 12px;
        border-bottom: 1px solid $darkGray;
        margin-bottom: 24px;

        > div:not(.controller-action) {
          display: flex;
          align-items: center;
          width: 100%;

          h1 {
            margin-left: 8px;
            font-size: 20px;
            font-weight: $fontBold;
            line-height: 20px;
          }
        }

        .controller-action {
          display: flex;
          align-items: center;
          justify-content: center;

          .dropdown {
            width: 30px;
            height: 30px;
            margin: 0 8 px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 100%;
              cursor: pointer;
            }

          }
        }

        .header-icon {
          padding: 4px 0;
          background-color: $mediumGray;
          border: none;
          width: 30px;
          height: 30px;

          &:hover,
          &:focus,
          &:active {
            border: none;
            box-shadow: none;
          }

          &:last-child {
            background-color: transparent;
            padding: 0;
          }
        }
      }

      .panel-body {
        height: 100%;
        padding: 0 12px;

        > .controllers {
          display: flex;
          flex-direction: column;

          .display-mode {
            width: 100%;
            margin-bottom: 32px;

            > p {
              font-size: 16px;
              font-weight: $fontHardBold;
              line-height: 20px;
              color: $mediumGray;
              margin-bottom: 8px;
            }

            .radio-group-wrapper-mode {
              width: 100%;

              > label {
                background: transparent;
                width: calc(100% / 4);
              }
          
            }
          }

          .specific-list {
            width: 100%;

            > p {
              font-size: 16px;
              font-weight: $fontHardBold;
              line-height: 20px;
              color: $mediumGray;
              margin-bottom: 8px;
            }

            > div {
              display: flex;
              flex-direction: column;

              > label {
                min-height: unset;
                line-height: unset;
                padding: 16px 8px;
                width: fit-content;
              }
            }

          }
        }
      }
    }
  }

}